import React from 'react';
import { Lock, People, Person } from '@mui/icons-material';

export const CURRENCIES = {
  RUB: {
    i18nLabel: 'rubLabel',
    symbol: '₽',
    value: 'RUB',
  },
  USD: {
    i18nLabel: 'usdLabel',
    symbol: '$',
    value: 'USD',
  },
  EUR: {
    i18nLabel: 'eurLabel',
    symbol: '€',
    value: 'EUR',
  },
};

export const IMPORTANCIES = {
  MUST_HAVE: {
    i18nLabel: 'mustHaveImportanceLabel',
    ordinal: 1,
    value: 'MUST_HAVE',
    className: 'text-white bg-[#e46a76]'
  },
  SHOULD_HAVE: {
    i18nLabel: 'shouldHaveImportanceLabel',
    ordinal: 2,
    value: 'SHOULD_HAVE',
    className: 'text-white bg-[#fb9678]'
  },
  WOULD_HAVE: {
    i18nLabel: 'wouldHaveImportanceLabel',
    ordinal: 3,
    value: 'WOULD_HAVE',
    className: 'text-primary bg-[#fec90f]'
  },
  NOT_NECESSARILY: {
    i18nLabel: 'notNecessarilyImportanceLabel',
    ordinal: 4,
    value: 'NOT_NECESSARILY',
    className: 'text-white bg-[#00c292]'
  },
};

export const ITEM_STATUS = {
  PUBLIC: {
    value: 'PUBLIC',
    icon: <People fontSize="inherit" className="text-xl" />,
  },
  PRIVATE: {
    value: 'PRIVATE',
    icon: <Lock fontSize="inherit" className="text-xl"  />,
  },
  FRIENDS_ONLY: {
    value: 'FRIENDS_ONLY',
    icon: <Person fontSize="inherit" className="text-xl"  />,
  },
};
