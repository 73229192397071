import React from 'react';
import MyListCard from '@ui-kit/components/Card/list/MyListCard';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'next-i18next';
import { Typography } from '@mui/material';
import Link from 'next/link';
import navigationRoutes from 'constants/navigationRoutes';

function Lists({ lists }) {
  const { t } = useTranslation();

  return isEmpty(lists)
    ? (
      <Typography variant="body1" className="px-4 text-left" color="text.secondary">
        {t('mainPage:adviceToCreatePersonalList')}
      </Typography>
    )
    : (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {map(lists, (myList, idx) => (
          <Link
            key={`${idx}_${myList?.listId}`}
            shallow={true}
            prefetch={false}
            href={{
              pathname: `${navigationRoutes.LISTS.path}/[listId]`,
              query: { listId: myList.listId },
            }}
            className="card-shadow rounded-[20px]"
          >
            <MyListCard
              list={myList}
            />
          </Link>
        ))}
      </div>
    );
}

export default Lists;
