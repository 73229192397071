import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import Button from '@ui-kit/components/Button/Button';
import navigationRoutes from 'constants/navigationRoutes';
import { fetchLists as fetchListsAPI } from '@api/listAPI';
import Lists from './Lists';
import Link from 'next/link';
import ListsStub from '../ListsStub';

function MyListsAuthorised() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [myLists, setMyLists] = useState();

  useEffect(() => {
    setLoading(true);
    fetchListsAPI({ limit: 8 }).then(({ data }) => {
      setMyLists(data);
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <div className="w-full flex flex-col gap-y-4 min-h-295">
      <div className="flex justify-start items-center">
        <Link shallow={true} prefetch={false} href={navigationRoutes.LISTS.path}>
          <Typography
            variant="h1"
            className="pr-4 inline-block sm:hidden"
          >
            {t('mainPage:myListsMainPage')}
          </Typography>
        </Link>
        <Typography
          variant="h1"
          className="pr-4 hidden sm:inline-block"
        >
          {t('mainPage:myListsMainPage')}
        </Typography>
        <Link shallow={true} prefetch={false} href={navigationRoutes.LISTS.path}>
          <Typography
            variant="body1"
            color="inherit"
            className="underline text-secondary hover:text-primary-main hidden sm:inline-block"
          >
            {t('mainPage:linkToAllMainPage')} {'>'}
          </Typography>
        </Link>
        <Link shallow={true} prefetch={false} href={`${navigationRoutes.LISTS.path}/draft`} className="ml-auto">
          <Button
            variant="outlined"
            className="bg-[rgb(251,150,120)] hover:bg-[rgb(251,150,120)] text-white"
          >
            {t('newList')}
          </Button>
        </Link>
      </div>
      {loading
        ? <ListsStub />
        : <Lists lists={myLists} />
      }
    </div>
  );
}

export default MyListsAuthorised;
