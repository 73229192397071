import React from 'react';
import { isEmpty } from 'lodash';
import MyListsAuthorised from 'components/pages/main/myLists/MyListsAuthorised';
import { useTranslation } from 'next-i18next';
import { Typography } from '@mui/material';
import Button from '@ui-kit/components/Button/Button';
import Link from 'next/link';
import navigationRoutes from 'constants/navigationRoutes';

function MyLists({ userToken }) {
  const { t } = useTranslation();

  return isEmpty(userToken)
    ? (
      <div className="w-full text-center">
        <Typography variant="body1" className="px-4 py-8" color="text.secondary">
          {t('mainPage:anonAdviceToCreatePersonalList')}
        </Typography>
        <Link shallow={true} prefetch={false} href={`${navigationRoutes.LISTS.path}/draft`}>
          <Button className="text-secondary-contrastText">{t('createList')}</Button>
        </Link>
      </div>
    )
    : <MyListsAuthorised />;
}

export default MyLists;
